import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide1/image/imgPortadaGuia1.png"
import img1 from "@components/pageGuide/guides/guide1/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide1/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide1/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide1/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      14 plantillas de mensajes para vender por WhatsApp Business API
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Las plantillas son formatos que usa WhatsApp para permitir a cualquier
      negocio iniciar la conversación con un contacto. Y en una aplicación de
      mensajería que cuenta con un open rate de más del 98% es importante saber
      cómo comunicarse con el cliente para vender más. Por eso, aquí tendrás 14
      ejemplos de plantillas que te ayudarán.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Si utilizas la API de WhatsApp Business debes aprovechar esta herramienta
    para aumentar tus ventas. Las plantillas son aprobadas por WhatsApp, lo que
    asegura una buena experiencia. Además, para que ellos la reciban, deben
    pertenecer a tu base de datos.
    <br /> <br />
    Por eso, en esta guía todas las plantillas van a ser aprobadas fácilmente
    por WhatsApp. Pero no solo copies y pegues el contenido, puedes hacerlo,
    pero es recomendable que adaptes estos mensajes de acuerdo a tu negocio. Así
    será mucho más efectivo.
  </p>
)

const data = {
  start: {
    support: "Guía | WhatsApp",
    title: title,
    description: textDescription,
    classtext: "guide__description1",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Plantillas para fidelizar a tus clientes.",
      },
      {
        key: 2,
        text: "Plantillas para reducir el abandono de carrito.",
      },
      {
        key: 3,
        text: "Plantillas para el seguimiento de pedidos.",
      },
      {
        key: 4,
        text: "Plantillas de descuentos y para días festivos.",
      },
      {
        key: 5,
        text: "Mensajes para venta cruzada.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
